import { Dispatch, SetStateAction } from 'react';

import { Tooltip } from '@analog/ui/src';
import allImgPaths from 'assets/allImgPaths';
import Badge from 'components/common/Badge';
import { Skeleton } from 'components/Skeleton';
import TableColumns from 'components/Table/TableColumns';
import { FontColors } from 'consts';
import { NavigateFunction } from 'react-router-dom';
import { routes } from 'routes/routesConst';
import { ColumnData } from 'types';
import { classes, getLinkFromNetwork, parseJson, timeAgo } from 'utils';

import Chip from '../../../../components/common/Chip';

import { GMPFilter } from './Filter';
import RenderNetwork from './RenderNetwork';

interface TaskColumnProps {
  navigate: NavigateFunction;
  withType?: boolean;
  visibleColumns?: string[];
  setPhase?: Dispatch<SetStateAction<string>>;
  hidePhaseFilter?: boolean;
}

type colorType = 'blue' | 'yellow' | 'green' | 'purple' | 'red' | 'brightGreen';
type dbPhase = 'submitted' | 'sign' | 'write' | 'read' | 'verified' | 'failed';

const DBtoStringValueMapping = {
  sign: 'signed',
  write: 'processing',
  read: 'completed',
  submitted: 'submitted',
  verified: 'verified',
  failed: 'failed',
};

export const StringToDBValueMapping = {
  signed: 'Sign',
  processing: 'Write',
  completed: 'Read',
  submitted: 'submitted',
  verified: 'Verified',
  failed: 'Failed',
  all: '',
};

const tooltipOfPhase = {
  submitted: 'Your GMP task has been tracked from source chain',
  sign: 'Your GMP task has been verified by the Timechain.',
  write: 'Your GMP task has been sent to the destination chain and is processing.',
  read: 'Successfully reached finality.',
  verified: 'Your GMP task has been verified on destination',
  failed: 'Your GMP task has been failed on destination',
};

const colors = {
  submitted: 'purple',
  sign: 'blue',
  write: 'yellow',
  read: 'green',
  failed: 'red',
  verified: 'brightGreen',
};

export const taskColumn = ({ navigate, setPhase, hidePhaseFilter = false }: TaskColumnProps) => {
  const columns = [
    {
      title: 'Salt',
      key: 'salt',
      render: (data: ColumnData) => (
        <div className="flex col-span-2 md:col-span-1">
          <div className={'flex flex-col justify-center overflow-hidden w-full'}>
            <Tooltip
              id={data[data.columnKey]}
              content={data[data.columnKey]}
              className="z-10 !rounded-lg"
            />
            <TableColumns
              withTruncate
              withCopyButton
              dataTooltipId={data[data.columnKey]}
              value={data[data.columnKey] || '-'}
              classNames={{
                content: data[data.columnKey] ? 'cursor-pointer w-fit' : 'w-fit',
                skeletonText: 'h-4 w-10',
                skeletonLabel: 'w-10',
              }}
              textColor={data[data.columnKey] ? FontColors.PURPLE : FontColors.WHITE}
              onClick={() => {
                data[data.columnKey].toString() &&
                  navigate(`${routes.task}/${data.salt.toString()}`);
              }}
            />
          </div>
        </div>
      ),
    },
    {
      title: 'Task ID',
      key: 'taskId',
      render: (data: ColumnData) => (
        <>
          <Tooltip
            id={data[data.columnKey]?.toString()}
            content={data[data.columnKey]?.toString()}
            className="max-w-[240px] !rounded-lg z-10"
          />
          {data.childTask && (
            <Tooltip
              id={data.childTask.toString().replace(/,/g, '')}
              content={data.childTask.toString().replace(/,/g, '')}
              className="max-w-[240px] !rounded-lg z-10"
            />
          )}
          <div className="flex flex-col gap-2 relative">
            <Skeleton.Loader>
              <div className={'flex items-center gap-2 w-full'}>
                <span className="w-[7px] h-[7px] bg-[#ffffffa3] flex rounded-full"></span>
                <TableColumns
                  dataTooltipId={data[data.columnKey]?.toString()}
                  value={data[data.columnKey] ? ' Send Task' : 'Pending'}
                  classNames={{
                    content: 'cursor-pointer w-fit leading-[22px]',
                    skeletonText: 'h-4 w-10',
                    skeletonLabel: 'w-10',
                  }}
                  textColor={FontColors.PURPLE}
                  onClick={() => {
                    data[data.columnKey]?.toString() &&
                      navigate(`${routes.task}/${data.taskId.toString()}`);
                  }}
                />
              </div>
              {data.childTask && (
                <div className={'flex items-center gap-2 w-full'}>
                  <span className="w-[7px] h-[7px] border border-[#ffffffa3] flex rounded-full before:content before:absolute before:border-r before:border-dashed before:border-[#ffffff3d] before:h-[18px] before:bottom-[17px] before:left-[2.5px]"></span>

                  <TableColumns
                    dataTooltipId={data.childTask.toString().replace(/,/g, '')}
                    value="Read Task"
                    classNames={{
                      content: 'cursor-pointer w-fit leading-[22px]',
                      skeletonText: 'h-4 w-10',
                      skeletonLabel: 'w-10',
                    }}
                    textColor={FontColors.PURPLE}
                    onClick={() => {
                      data.childTask?.toString() &&
                        navigate(`${routes.task}/${data.childTask.toString().replace(/,/g, '')}`);
                    }}
                  />
                </div>
              )}
            </Skeleton.Loader>
          </div>
        </>
      ),
    },
    {
      title: 'Method',
      key: 'type',
      render: (data: ColumnData) => (
        <Chip
          value={data[data.columnKey] || '-'}
          classNames={{
            base: 'md:justify-start',
            container: 'max-w-fit bg-[#232222]',
            skeletonText: 'md:w-[100px] w-full',
            content: 'max-w-auto text-white',
          }}
        />
      ),
    },
    {
      title: 'Source',
      key: 'source',
      render: (data: ColumnData) => {
        const parsedData = parseJson(data.sourceNetwork);

        return (
          <div className="flex flex-col gap-2">
            <div
              className={classes(
                'flex gap-1 flex-wrap md:flex-shrink flex-shrink-0',
                !parsedData.length && ' md:pl-0'
              )}
            >
              <RenderNetwork network={data.sourceNetwork} element="first" />
            </div>

            {data[data.columnKey] && (
              <Chip
                icon={
                  <img src={allImgPaths.destinationAddress} alt="task" className="w-full h-full" />
                }
                value={data[data.columnKey]}
                colIndex={data.salt}
                withCopyButton
                withTruncate
                copyValue={data[data.columnKey]}
                onClick={() => {
                  const redirectLink = getLinkFromNetwork(
                    `address/${data[data.columnKey]}`,
                    data['sourceNetwork']
                  );
                  redirectLink && window.open(redirectLink, '_blank');
                }}
                classNames={{
                  base: 'md:justify-start',
                  container: 'max-w-fit bg-opacity-[0.16] bg-[#E563F9]',
                  skeletonText: 'md:w-[100px] w-full',
                  content: 'max-w-auto text-[#E563F9] cursor-pointer',
                }}
              />
            )}
            {data.srcTxHash && (
              <Chip
                icon={
                  <img src={allImgPaths.destinationHash} alt="task" className="w-full h-full" />
                }
                value={data.srcTxHash || '-'}
                withCopyButton
                withTruncate
                copyValue={data.srcTxHash}
                onClick={() => {
                  const redirectLink = getLinkFromNetwork(
                    `tx/${data.srcTxHash}`,
                    data['sourceNetwork']
                  );
                  redirectLink && window.open(redirectLink, '_blank');
                }}
                classNames={{
                  base: 'md:justify-start',
                  container: 'max-w-fit',
                  skeletonText: 'md:w-[100px] w-full',
                  content: 'max-w-auto text-[#E563F9] cursor-pointer',
                }}
              />
            )}
            {/* <TableColumns
              value={data[data.columnKey]}
              withTruncate
              withCopyButton
              colIndex={data.colIndex}
              textColor={data[data.columnKey] ? FontColors.PURPLE : FontColors.WHITE}
              onClick={() => {
                const redirectLink = getLinkFromNetwork(
                  `address/${data[data.columnKey]}`,
                  data['sourceNetwork']
                );
                redirectLink && window.open(redirectLink, '_blank');
              }}
              copyValue={data[data.columnKey]}
              width="100%"
              classNames={{
                base: 'md:justify-start w-[65%] md:w-full',
                content: classes(data[data.columnKey] ? 'cursor-pointer' : 'md:pl-0'),
                container: 'w-[100%]',
                skeletonText: 'md:w-[100px] w-full',
              }}
            /> */}
          </div>
        );
      },
    },
    {
      title: 'Destination',
      key: 'destTxHash',
      render: (data: ColumnData) => {
        if (!data.network && !data.destination && !data.destTxHash) {
          return <span>-</span>;
        }
        return (
          <div className="flex flex-col gap-2">
            <div className={classes('flex gap-1 flex-wrap')}>
              <RenderNetwork network={data.network} element="first" />
            </div>
            {data.destination && (
              <Chip
                icon={
                  <img src={allImgPaths.destinationAddress} alt="task" className="w-full h-full" />
                }
                value={data.destination}
                colIndex={data.salt}
                withCopyButton
                withTruncate
                copyValue={data.destination}
                onClick={() => {
                  const redirectLink = getLinkFromNetwork(
                    `address/${data.destination}`,
                    data['network']
                  );
                  redirectLink && window.open(redirectLink, '_blank');
                }}
                classNames={{
                  base: 'md:justify-start',
                  container: 'max-w-fit bg-opacity-[0.16] bg-[#E563F9]',
                  skeletonText: 'md:w-[100px] w-full',
                  content: 'max-w-auto text-[#E563F9] cursor-pointer',
                }}
              />
            )}
            {data[data.columnKey] && (
              <Chip
                icon={
                  <img src={allImgPaths.destinationHash} alt="task" className="w-full h-full" />
                }
                value={data[data.columnKey] || '-'}
                withCopyButton
                withTruncate
                copyValue={data[data.columnKey]}
                onClick={() => {
                  const redirectLink = getLinkFromNetwork(
                    `tx/${data[data.columnKey]}`,
                    data['network']
                  );
                  redirectLink && window.open(redirectLink, '_blank');
                }}
                classNames={{
                  base: 'md:justify-start',
                  container: 'max-w-fit',
                  skeletonText: 'md:w-[100px] w-full',
                  content: 'max-w-auto text-[#E563F9] cursor-pointer',
                }}
              />
            )}
          </div>
        );
      },
    },
    {
      title: 'Status',
      renderHeader: !hidePhaseFilter
        ? (props: any, key: string) => <GMPFilter setPhase={setPhase} key={key} {...props} />
        : '',
      key: 'phase',
      render: (data: ColumnData) => {
        const val: dbPhase = data[data.columnKey]?.toLowerCase();
        return (
          <>
            <Tooltip
              id={tooltipOfPhase[val]}
              content={tooltipOfPhase[val]}
              className="max-w-[220px] !rounded-lg z-10"
            />
            <Skeleton.Loader className="w-20 h-5">
              <Badge
                label={DBtoStringValueMapping['submitted']}
                badgeColor={colors['submitted'] as colorType}
                data-tooltip-id={val === 'submitted' ? tooltipOfPhase['submitted'] : ''}
                disabled={val !== 'submitted'}
              />
              <Badge
                label={DBtoStringValueMapping['sign']}
                badgeColor={colors['sign'] as colorType}
                data-tooltip-id={val === 'sign' ? tooltipOfPhase['sign'] : ''}
                disabled={val !== 'sign'}
              />
              <Badge
                label={DBtoStringValueMapping['write']}
                badgeColor={colors['write'] as colorType}
                data-tooltip-id={val === 'write' ? tooltipOfPhase['write'] : ''}
                disabled={val !== 'write'}
              />
              <Badge
                label={DBtoStringValueMapping['read']}
                badgeColor={colors['read'] as colorType}
                data-tooltip-id={val === 'read' ? tooltipOfPhase['read'] : ''}
                disabled={val !== 'read'}
              />

              {data.verification === 'verified' && (
                <Badge
                  label={DBtoStringValueMapping['verified']}
                  badgeColor={colors['verified'] as colorType}
                  data-tooltip-id={
                    data.verification === 'verified' ? tooltipOfPhase['verified'] : ''
                  }
                  disabled={data.verification !== 'verified'}
                />
              )}
              {data.verification === 'failed' && (
                <Badge
                  label={DBtoStringValueMapping['failed']}
                  badgeColor={colors['failed'] as colorType}
                  data-tooltip-id={data.verfifcation === 'failed' ? tooltipOfPhase['failed'] : ''}
                  disabled={data.verification !== 'failed'}
                />
              )}
            </Skeleton.Loader>
          </>
        );
      },
    },
    {
      title: 'Created at',
      key: 'created_at',
      render: (data: ColumnData) => (
        <TableColumns
          value={data[data.columnKey] ? timeAgo(new Date(data[data.columnKey])) : '-'}
        />
      ),
    },
  ];

  return columns;
};
