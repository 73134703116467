import { useState } from 'react';

import { DEFAULT_PAGE_SIZE } from 'consts';
import { useApiAccess } from 'contexts/AccessProvider';
import { useListAccountsQuery } from 'gql';
import { usePagination, useSortBy } from 'hooks';

import AccountColumn from './components/AccountColumn';

function useAccountList() {
  const { sessionKey } = useApiAccess();

  const [accountData, setAccountData] = useState<Array<any>>();
  const { PageController, setTotalDataCount, pageSize, pageNumber } = usePagination({
    defaultPageSize: DEFAULT_PAGE_SIZE,
  });

  const { sortField, sortOrder, onSort } = useSortBy();

  const { loading: isLoading } = useListAccountsQuery({
    variables: {
      sessionKey,
      page: { size: pageSize, number: pageNumber },
      ...(sortField && {
        sort: { field: sortField, order: sortOrder },
      }),
    },
    onCompleted(data) {
      setTotalDataCount(data.listAccounts.count);
      const accountList = data.listAccounts.data?.map((account) => {
        return {
          address: account?.address,
          free: account?.data?.data?.free,
          reserved: account?.data?.data?.reserved,
          publicAddress: account?.publicAddress,
        };
      });
      setAccountData(accountList);
    },

    fetchPolicy: 'cache-and-network',
  });
  const accountColumn = AccountColumn();

  return {
    PageController,
    accountData,
    isLoading,
    pageSize,
    sortOrder,
    onSort,
    accountColumn,
  };
}

export default useAccountList;
