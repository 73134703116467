import { Identicon } from 'components';
import TableColumns from 'components/Table/TableColumns';
import { FontColors } from 'consts';
import { Link } from 'react-router-dom';
import { routes } from 'routes/routesConst';
import { formatAmount } from 'utils';

const AccountColumn = () => [
  {
    title: 'Account',
    key: 'publicAddress',
    render(data: any) {
      return (
        <Link
          className="flex gap-[10px] col-span-2 md:col-span-1 cursor-pointer items-center"
          to={`${routes.account}/${data[data.columnKey]}`}
          state={{ data: data }}
        >
          <Identicon value={data[data.columnKey]} size={24} />
          <div className={'flex flex-col overflow-hidden w-full'}>
            <TableColumns
              value={data[data.columnKey]}
              withTruncate
              withCopyButton
              textColor={FontColors.PURPLE}
            />
          </div>
        </Link>
      );
    },
  },
  {
    title: 'Reserved',
    key: 'reserved',
    render: (data: any) => (
      <TableColumns
        value={formatAmount(data[data.columnKey])}
        classNames={{ beforeIcon: 'h-[9px] w-[9px]', content: 'capitalize text-white' }}
      />
    ),
  },
  {
    title: 'Balance',
    key: 'free',
    render: (data: any) => (
      <TableColumns
        value={formatAmount(data[data.columnKey])}
        classNames={{ beforeIcon: 'h-[9px] w-[9px]', content: 'capitalize text-white' }}
      />
    ),
  },
];

export default AccountColumn;
