import { CopyToClipboard } from '@analog/ui/src';
import { classes } from 'utils';

import useTruncate from './useTruncate';

export interface TruncatedTextProps {
  originalText: string;
  width?: string;
  className?: string;
  withCopyButton?: boolean;
  colIndex?: string;
  textColor?: string;
  onClick?: () => void;
}
const TruncatedText = ({
  originalText,
  className,
  width = '100%',
  withCopyButton = false,
  colIndex,
  textColor,
  onClick,
  ...rest
}: TruncatedTextProps) => {
  const { truncatedText, containerRef } = useTruncate({ originalText });

  return (
    <div
      ref={containerRef}
      className={classes(
        'text-sm whitespace-wrap text-ellipsis overflow-hidden',
        withCopyButton && 'flex gap-1 items-center',
        textColor,
        className
      )}
      style={{ width: width }}
    >
      <div onClick={onClick} {...rest}>
        {truncatedText}
      </div>
      {withCopyButton && originalText && originalText != '-' && (
        <CopyToClipboard icon content={originalText} id={originalText + colIndex} />
      )}
    </div>
  );
};

export default TruncatedText;
