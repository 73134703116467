import { useApiAccess } from 'contexts/AccessProvider';
import { useTaskDetailsQuery } from 'gql';
import { useParams } from 'react-router-dom';

import TaskData from './components/TaskData';

const useGMPDetails = () => {
  const { id } = useParams();
  const { sessionKey } = useApiAccess();

  const task = id?.startsWith('0x') ? { hash: id } : { taskId: Number(id) };

  const {
    data: taskDetailsData,
    loading: isLoading,
    error,
  } = useTaskDetailsQuery({
    variables: { sessionKey, ...task },
  });

  const taskData = TaskData({ taskDetailsData, isLoading });
  const title = 'Details';
  return {
    taskData,
    title,
    isLoading,
    id: taskDetailsData?.taskDetails.taskId || taskDetailsData?.taskDetails.salt,
    error,
  };
};
export default useGMPDetails;
