import allImgPaths from 'assets/allImgPaths';
import { Card, Table } from 'components';
import { routes } from 'routes/routesConst';
import { arrayOfSize } from 'utils';

import useLatestExtrinsics from './useLatestExtrinsics';

export const LatestExtrinsics = () => {
  const { Navigate, extrinsicsListLoading, COLUMNS, extrinsicsList } = useLatestExtrinsics();
  return (
    <Card>
      <div className="flex flex-col gap-5">
        <div className="flex justify-between">
          <span className="text-white text-xl">Latest Extrinsics</span>
          {extrinsicsList?.listExtrinsics.data?.length && (
            <div
              className="flex flex-row gap-[5px] items-center"
              onClick={() => Navigate(routes.extrinsics)}
            >
              <span className="text-white whitespace-nowrap cursor-pointer">View All</span>
              <img
                src={allImgPaths.rightArrowIcon}
                alt="right-arrow"
                className="h-[14px] w-[14px] md:h-4 md:w-4 mt-[2.85px]"
              />
            </div>
          )}
        </div>
        <div>
          <Table
            columns={COLUMNS}
            animation
            classNames={{
              row: 'grid gap-x-2 grid-cols-3 md:grid-cols-[minmax(150px,40%)_minmax(90px,35%)_minmax(120px,40%)] !py-4',
              bodyBase: 'h-[360px] overflow-hidden',
              bodyWrapper:
                'flex flex-col-reverse min-h-[360px] h-[360px] max-h-[360px] overflow-auto',
            }}
            data={
              !extrinsicsListLoading
                ? [...(extrinsicsList?.listExtrinsics?.data || [])].reverse()
                : arrayOfSize(10)
            }
            isHeaderHidden
            bodyId="extrinsics"
            isLoading={extrinsicsListLoading}
          />
        </div>
      </div>
    </Card>
  );
};
