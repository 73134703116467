import { CopyToClipboard, Icon, NetworkDetails, TruncateString } from '@analog/ui';
import allImgPaths from 'assets/allImgPaths';
import { Text } from 'components';
import JsonTable from 'components/common/JsonTable';
import { Skeleton } from 'components/Skeleton';
import { FontColors } from 'consts';
import { TaskDetailsQuery } from 'gql';
import { useWindowSize } from 'hooks';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { routes } from 'routes/routesConst';
import { GMPTaskType } from 'types';
import { getLinkFromNetwork, timeAgo } from 'utils';

interface TaskDataProps {
  taskDetailsData: TaskDetailsQuery | undefined;
  isLoading: boolean;
}

const TaskData = ({ taskDetailsData, isLoading }: TaskDataProps) => {
  const navigate = useNavigate();
  const { width } = useWindowSize();
  return [
    {
      icon: <Icon icon={allImgPaths.taskIcon} />,
      label: 'Task ID',
      value: <div className="text-pink">{taskDetailsData?.taskDetails.taskId}</div>,
      enable: !!taskDetailsData?.taskDetails.taskId,
    },
    {
      icon: <Icon icon={allImgPaths.taskIcon} />,
      label: 'GMP ID',
      value: <div className="text-pink">{taskDetailsData?.taskDetails.gmpId}</div>,
      enable: !!taskDetailsData?.taskDetails.gmpId,
    },

    {
      icon: <Icon icon={allImgPaths.hashTagOutlineIcon} />,
      label: 'Shard ID',
      value: (
        <div
          className="text-pink cursor-pointer"
          onClick={() => {
            taskDetailsData?.taskDetails.shardId &&
              navigate(`${routes.shard}/${taskDetailsData.taskDetails.shardId.toString()}`);
          }}
        >
          {taskDetailsData?.taskDetails.shardId}
        </div>
      ),
      enable: !!taskDetailsData?.taskDetails.shardId || false,
    },

    {
      icon: <Icon icon={allImgPaths.phaseIcon} />,
      label: 'Phase',
      value: <div className="text-white">{taskDetailsData?.taskDetails.phase}</div>,
      enable: !!taskDetailsData?.taskDetails.phase || false,
    },

    {
      icon: <Icon icon={allImgPaths.phaseIcon} />,
      label: 'Verification',
      value: <div className="text-white">{taskDetailsData?.taskDetails.verification}</div>,
      enable: !!taskDetailsData?.taskDetails.verification || false,
    },

    {
      icon: <Icon icon={allImgPaths.cube3DIcon} />,
      label: 'Salt',
      value: (
        <>
          <div className="flex flex-row gap-[6px] items-center text-white">
            <TruncateString text={taskDetailsData?.taskDetails.salt as string} />
            {taskDetailsData?.taskDetails.salt && (
              <CopyToClipboard
                icon
                content={taskDetailsData?.taskDetails.salt}
                id={taskDetailsData?.taskDetails.salt}
              />
            )}
          </div>
        </>
      ),
      enable: !!taskDetailsData?.taskDetails.salt,
    },
    {
      icon: <Icon icon={allImgPaths.sourceNetworkIcon} />,
      label: 'Source Network',
      value: taskDetailsData?.taskDetails.type === GMPTaskType.SendMessage && (
        <div className="flex gap-1 text-white">
          {taskDetailsData?.taskDetails.sourceNetwork ? (
            <NetworkDetails data={taskDetailsData?.taskDetails.sourceNetwork} />
          ) : (
            <Skeleton.Loader className="h-4 w-10">-</Skeleton.Loader>
          )}
        </div>
      ),
      enable: !!taskDetailsData?.taskDetails.sourceNetwork,
    },

    {
      icon: <Icon icon={allImgPaths.shardNetworkIcon} />,
      label: 'Destination Network',
      value: (
        <div className="flex gap-1 text-white flex-wrap">
          {taskDetailsData?.taskDetails.network ? (
            <NetworkDetails data={taskDetailsData?.taskDetails.network} />
          ) : (
            <Skeleton.Loader className="h-4 w-10">-</Skeleton.Loader>
          )}
        </div>
      ),
      enable: !!taskDetailsData?.taskDetails.network,
    },

    {
      icon: <Icon icon={allImgPaths.globalSearchIcon} />,
      label: 'Source Address',
      value: (
        <div className="flex flex-row gap-[6px] items-center text-white">
          <div
            className="cursor-pointer text-pink"
            onClick={() => {
              const redirectLink = getLinkFromNetwork(
                `address/${taskDetailsData?.taskDetails.source}`,
                taskDetailsData?.taskDetails.sourceNetwork
              );
              redirectLink && window.open(redirectLink, '_blank');
            }}
          >
            <TruncateString text={taskDetailsData?.taskDetails.source as string} />
          </div>
          {taskDetailsData?.taskDetails.source && (
            <CopyToClipboard
              icon
              content={taskDetailsData?.taskDetails.source}
              id={taskDetailsData?.taskDetails.source}
            />
          )}
        </div>
      ),
      enable: !!taskDetailsData?.taskDetails.source,
    },

    {
      icon: <Icon icon={allImgPaths.globalSearchIcon} />,
      label: 'Destination Address',
      value: (
        <div className="flex flex-row gap-[6px] items-center text-white">
          <div
            className="cursor-pointer text-pink"
            onClick={() => {
              const redirectLink = getLinkFromNetwork(
                `address/${taskDetailsData?.taskDetails.destination}`,
                taskDetailsData?.taskDetails.network
              );
              redirectLink && window.open(redirectLink, '_blank');
            }}
          >
            <TruncateString text={taskDetailsData?.taskDetails.destination as string} />
          </div>
          {taskDetailsData?.taskDetails.destination && (
            <CopyToClipboard
              icon
              content={taskDetailsData?.taskDetails.destination}
              id={taskDetailsData?.taskDetails.destination}
            />
          )}
        </div>
      ),
      enable: !!taskDetailsData?.taskDetails.destination,
    },

    {
      icon: <Icon icon={allImgPaths.hashTagIcon} />,
      label: 'Destination Transaction Hash',
      value: (
        <div className="flex flex-row gap-[6px] items-center text-white">
          <div
            className="cursor-pointer text-pink"
            onClick={() => {
              const redirectLink = getLinkFromNetwork(
                `tx/${taskDetailsData?.taskDetails.destTxHash}`,
                taskDetailsData?.taskDetails.network
              );
              redirectLink && window.open(redirectLink, '_blank');
            }}
          >
            <TruncateString text={taskDetailsData?.taskDetails.destTxHash as string} />
          </div>
          {taskDetailsData?.taskDetails.destTxHash && (
            <CopyToClipboard
              icon
              content={taskDetailsData?.taskDetails.destTxHash}
              id={taskDetailsData?.taskDetails.destTxHash}
            />
          )}
        </div>
      ),
      enable: !!taskDetailsData?.taskDetails.destTxHash,
    },

    {
      icon: <Icon icon={allImgPaths.shardSignature} />,
      label: 'Signature',
      value: (
        <div className="flex flex-row gap-[6px] items-center text-white">
          <TruncateString
            text={taskDetailsData?.taskDetails.sig as string}
            textLength={width > 1440 ? 35 : undefined}
          />
          {taskDetailsData?.taskDetails.sig && (
            <CopyToClipboard
              icon
              content={taskDetailsData?.taskDetails.sig}
              id={taskDetailsData?.taskDetails.sig}
            />
          )}
        </div>
      ),
      enable: !!taskDetailsData?.taskDetails.sig,
    },

    {
      icon: <Icon icon={allImgPaths.unlinkIcon} />,
      label: 'Unassigned Reason',
      value: (
        <div className="text-white">{taskDetailsData?.taskDetails.unAssignedReason || '-'}</div>
      ),
      enable: !!taskDetailsData?.taskDetails.unAssignedReason,
    },

    {
      icon: <Icon icon={allImgPaths.transferIcon} />,
      label: 'Proposed At Block',
      value: (
        <Text
          extraClass="cursor-pointer"
          color={FontColors.PURPLE}
          onClick={() =>
            taskDetailsData?.taskDetails?.proposed_at_block &&
            navigate(`${routes.block}/${taskDetailsData?.taskDetails?.proposed_at_block}`)
          }
        >
          {taskDetailsData?.taskDetails?.proposed_at_block}
        </Text>
      ),
      enable: !!taskDetailsData?.taskDetails.proposed_at_block,
    },
    {
      icon: <Icon icon={allImgPaths.clipboardTickIcon} />,
      label: 'Modified At Block',
      value: (
        <Text
          extraClass="cursor-pointer"
          color={FontColors.PURPLE}
          onClick={() =>
            taskDetailsData?.taskDetails?.modified_at_block &&
            navigate(`${routes.block}/${taskDetailsData?.taskDetails?.modified_at_block}`)
          }
        >
          {taskDetailsData?.taskDetails?.modified_at_block}
        </Text>
      ),
      enable: !!taskDetailsData?.taskDetails.modified_at_block,
    },

    {
      icon: <Icon icon={allImgPaths.clockIcon} />,
      label: 'Created At',
      value: taskDetailsData?.taskDetails?.created_at
        ? `${timeAgo(new Date(taskDetailsData?.taskDetails?.created_at))} (${moment
            .utc(taskDetailsData?.taskDetails?.created_at)
            .format('MMM-D-YYYY h:mm:ss A +UTC')})`
        : '-',
      enable: !!taskDetailsData?.taskDetails.created_at,
    },
    {
      icon: <Icon icon={allImgPaths.clockIcon} />,
      label: 'Updated At',
      value: taskDetailsData?.taskDetails?.updated_at
        ? `${timeAgo(new Date(taskDetailsData?.taskDetails?.updated_at))} (${moment
            .utc(taskDetailsData?.taskDetails?.updated_at)
            .format('MMM-D-YYYY h:mm:ss A +UTC')})`
        : '-',
      enable: !!taskDetailsData?.taskDetails.updated_at,
    },
    {
      icon: <Icon icon={allImgPaths.clipboardTickIcon} />,
      label: 'Source Block',
      value: (
        <Text
          extraClass="cursor-pointer"
          color={FontColors.PURPLE}
          onClick={() => {
            const redirectLink = getLinkFromNetwork(
              `block/${taskDetailsData?.taskDetails.srcBlock}`,
              taskDetailsData?.taskDetails.sourceNetwork
            );
            redirectLink && window.open(redirectLink, '_blank');
          }}
        >
          {taskDetailsData?.taskDetails?.srcBlock}
        </Text>
      ),
      enable: !!taskDetailsData?.taskDetails.srcBlock,
    },
    {
      icon: <Icon icon={allImgPaths.clipboardTickIcon} />,
      label: 'Destination Block',
      value: (
        <Text extraClass="cursor-pointer" color={FontColors.PURPLE}>
          {taskDetailsData?.taskDetails?.destBlock}
        </Text>
      ),
      enable: !!taskDetailsData?.taskDetails.destBlock,
    },
    {
      icon: <Icon icon={allImgPaths.hashTagIcon} />,
      label: 'Source Transaction hash',
      value: (
        <div className="flex flex-row gap-[6px] items-center text-white">
          <div
            className="cursor-pointer text-pink"
            onClick={() => {
              const redirectLink = getLinkFromNetwork(
                `tx/${taskDetailsData?.taskDetails.srcTxHash}`,
                taskDetailsData?.taskDetails.sourceNetwork
              );
              redirectLink && window.open(redirectLink, '_blank');
            }}
          >
            <TruncateString text={taskDetailsData?.taskDetails.srcTxHash as string} />
          </div>
          {taskDetailsData?.taskDetails.srcTxHash && (
            <CopyToClipboard
              icon
              content={taskDetailsData?.taskDetails.srcTxHash}
              id={taskDetailsData?.taskDetails.srcTxHash}
            />
          )}
        </div>
      ),
      enable: !!taskDetailsData?.taskDetails.srcTxHash,
    },
    {
      icon: <Icon icon={allImgPaths.jsonIcon} />,
      label: 'Payload (JSON)',
      collapsible: true,
      value: (
        <div>
          {taskDetailsData?.taskDetails.payload && (
            <JsonTable
              index={taskDetailsData?.taskDetails.payload.toString() as string}
              jsonData={taskDetailsData?.taskDetails.payload}
            />
          )}
        </div>
      ),
      enable: !!taskDetailsData?.taskDetails.payload,
    },
    {
      icon: <Icon icon={allImgPaths.infoIcon} />,
      label: 'JSON Definition',
      collapsible: true,
      value: (
        <div>
          {taskDetailsData?.taskDetails.definition && (
            <JsonTable
              index={taskDetailsData?.taskDetails.definition.toString() as string}
              jsonData={taskDetailsData?.taskDetails.definition}
            />
          )}
        </div>
      ),
      enable: !!taskDetailsData?.taskDetails.definition,
    },
    {
      icon: <Icon icon={allImgPaths.infoIcon} />,
      label: 'Source Transaction Log',
      collapsible: true,
      value: (
        <div>
          {taskDetailsData?.taskDetails.srcTxLog && (
            <JsonTable
              index={taskDetailsData?.taskDetails.srcTxLog.toString() as string}
              jsonData={taskDetailsData?.taskDetails.srcTxLog}
            />
          )}
        </div>
      ),
      enable: !!taskDetailsData?.taskDetails.srcTxLog,
    },
    {
      icon: <Icon icon={allImgPaths.infoIcon} />,
      label: 'Destination Transaction Log',
      collapsible: true,
      value: (
        <div>
          {taskDetailsData?.taskDetails.destTxLog && (
            <JsonTable
              index={taskDetailsData?.taskDetails.destTxLog.toString() as string}
              jsonData={taskDetailsData?.taskDetails.destTxLog}
            />
          )}
        </div>
      ),
      enable: !!taskDetailsData?.taskDetails.destTxLog,
    },
  ];
};
export default TaskData;
