import ExtrinsicsColumns from 'components/common/ExtrinsicsColumns';
import { DEFAULT_PAGE_SIZE } from 'consts';
import { useApiAccess } from 'contexts/AccessProvider';
import { useAccountDetailsQuery, useGetExtrinsicsQuery } from 'gql';
import { usePagination, useSortBy } from 'hooks';
import { useParams } from 'react-router-dom';

import AccountData from './components/AccountData';

const useAccountDetails = () => {
  const { id } = useParams();
  const { sessionKey } = useApiAccess();
  const {
    data: account,
    loading: isAccountLoading,
    error,
  } = useAccountDetailsQuery({
    variables: {
      publicAddress: id || '',
      sessionKey,
    },
  });

  const accountData = AccountData({ data: account });

  const EXTRINSICS_COLUMNS = ExtrinsicsColumns();

  const { PageController, setTotalDataCount, pageSize, pageNumber } = usePagination({
    defaultPageSize: DEFAULT_PAGE_SIZE,
  });

  const { sortField, sortOrder, onSort } = useSortBy();

  const { data: extrinsicData, loading: isExtrinsicsLoading } = useGetExtrinsicsQuery({
    variables: {
      sessionKey,
      signer: id,
      page: { size: pageSize, number: pageNumber },
      ...(sortField && {
        sort: { field: sortField, order: sortOrder },
      }),
    },
    onCompleted(data) {
      setTotalDataCount(data.getExtrinsics.count);
    },
    fetchPolicy: 'cache-and-network',
    skip: !id,
  });

  return {
    id: account?.accountDetails.publicAddress,
    accountData,
    isExtrinsicsLoading,
    extrinsicData: extrinsicData?.getExtrinsics,
    EXTRINSICS_COLUMNS,
    PageController,
    pageSize,
    onSort,
    sortOrder,
    isAccountLoading,
    error,
  };
};
export default useAccountDetails;
